import {
  AppstoreAddOutlined, AuditOutlined, BankOutlined, DollarOutlined, FileDoneOutlined, FileSyncOutlined, GoldOutlined, SkinOutlined,
  TeamOutlined, ScissorOutlined, DeploymentUnitOutlined, PaperClipOutlined, PartitionOutlined ,NodeIndexOutlined, OneToOneOutlined,
  HddOutlined, IdcardOutlined, PullRequestOutlined, ShoppingCartOutlined, SlidersOutlined, SolutionOutlined, GroupOutlined,
  DiffOutlined
} from "@ant-design/icons";

import { Image, Menu } from "antd";
import { React, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import backPhoto from "../../assets/images/logo-lookup.png";
import Can from "../../security/Can";

const { SubMenu } = Menu;

function SideNavigation() {
  const history = useHistory();
  const location = useLocation();
  const [myKey, setMyKey] = useState([]);
  const [openSub, setOpenSub] =  useState([]);
  const navigate = (location) => {
    history.push(location);
  };
  useEffect(() => {
    getRoute();
  }, [location]);

  const getRoute = () => {
    const selectedRoute = [];
    window.scrollTo({ top: 0, behavior: "smooth" });
    switch (location.pathname) {
      case "/":
        selectedRoute.push("1");
        break;
      case "/client":
        selectedRoute.push("2");
        break;
      case "/fournisseur":
        selectedRoute.push("3");
        break;
      case "/bon_Commandes":
        selectedRoute.push("4");
        break;
      case "/bon_Receptions":
        selectedRoute.push("5");
        break;
      case "/facture_achat":
        selectedRoute.push("6");
        break;
      case "/devis_list":
        selectedRoute.push("7");
        break;
      case "/accompte":
        selectedRoute.push("8");
        break;
      case "/v/bon_Commandes":
        selectedRoute.push("9");
        break;
      case "/v/bon_livraisons":
        selectedRoute.push("10");
        break;
      case "/facture_vente":
        selectedRoute.push("11");
        break;
      case "/tickets_caisse":
        selectedRoute.push("12");
        break;
      case "/s/bon_livraisons":
        selectedRoute.push("13");
        break;
      case "/s/factures":
        selectedRoute.push("14");
        break;
      case "/produit":
        selectedRoute.push("15");
        break;
      case "/projet":
        selectedRoute.push("17");
        break;
      case "/employe":
        selectedRoute.push("18");
        break;
      case "/stock":
        selectedRoute.push("20");
        break;
      case "/bon_transfert":
        selectedRoute.push("21");
        break;
      case "/bon_retour":
        selectedRoute.push("22");
        break;
      case "/list/inventaires":
        selectedRoute.push("23");
        break;
      case "/order_coupe":
        selectedRoute.push("30");
        break;
      case "/broderie":
        selectedRoute.push("31");
        break;
      case "/assemblage":
        selectedRoute.push("32");
        break;
      case "/confection":
        selectedRoute.push("33");
        break;
      case "/stock_coupe":
        selectedRoute.push("34");
        break;
      case "/stock_broderie":
        selectedRoute.push("35");
        break;
      case "/stock_assemblage":
        selectedRoute.push("36");
        break;
      case "/stock_produits":
        selectedRoute.push("37");
        break;
      case "finance_client":
        selectedRoute.push("38")
        break
        case "finance_fournisseur":
          selectedRoute.push("39")
        break
        case "mouvement_stock":
          selectedRoute.push("40")
        break
      default:
    }
    setMyKey(selectedRoute);
  };

  return (
    <>
      <div className="my-2 py-2 logo ">
        <div className="text-center">
          <Image
            preview={false}
            src={backPhoto}
            className="backGroundPic px-5 "
          />
        </div>
      </div>

      <Menu theme="dark" selectedKeys={myKey} mode="inline">
        <Menu.Item
          key="1"
          icon={<BankOutlined />}
          onClick={() => navigate("/")}
          disabled={!Can("7_1")}
        >
          Acceuil
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<IdcardOutlined />}
          onClick={() => navigate("/client")}
          disabled={!Can("8_1")}
        >
          Clients
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<SolutionOutlined />}
          onClick={() => navigate("/fournisseur")}
          disabled={!Can("9_1")}
        >
          Fournisseur
        </Menu.Item>
        <SubMenu key="Achat" icon={<DollarOutlined />} title="Achat">
          <Menu.Item
            icon={<ShoppingCartOutlined />}
            disabled={!Can("10_1")}
            key="4"
            onClick={() => navigate("/bon_Commandes")}
          >
            Bon commande
          </Menu.Item>
          {/* <Menu.Item
            icon={<FileDoneOutlined />}
            disabled={!Can("11_1")}
            key="5"
            onClick={() => navigate("/bon_Receptions")}
          >
            Bon réception
          </Menu.Item>
          <Menu.Item
            icon={<AuditOutlined />}
            disabled={!Can("12_1")}
            key="6"
            onClick={() => navigate("/facture_achat")}
          >
            Facture
          </Menu.Item> */}
        </SubMenu>

        <SubMenu key="Production" icon={<DollarOutlined />} title="Production">
          <Menu.Item
            icon={<ScissorOutlined />}
            key="30"
            onClick={() => navigate("/order_coupe")}
          >
            Coupe
          </Menu.Item>
          <Menu.Item
            icon={<DeploymentUnitOutlined />}
            key="31"
            onClick={() => navigate("/broderie")}
          >
            Broderie
          </Menu.Item>
          <Menu.Item
            key="32"
            icon={<PaperClipOutlined />}
            disabled={!Can("24_1")}
            onClick={() => navigate("/assemblage")}
          >
            Assemblage
          </Menu.Item>
          <Menu.Item
          key="33"
            icon={<NodeIndexOutlined />}
            disabled={!Can("24_1")}
            onClick={() => navigate("/confection")}
          >
            Confection
          </Menu.Item>
  
        </SubMenu>

        {/* <SubMenu key="Vente" icon={<DollarOutlined />} title="Vente">
          <Menu.Item
            icon={<DiffOutlined />}
            disabled={!Can("13_1")}
            key="7"
            onClick={() => navigate("/devis_list")}
          >
            Devis
          </Menu.Item>
          <Menu.Item
            icon={<FileDoneOutlined />}
            disabled={!Can("14_1")}
            key="9"
            onClick={() => navigate("/v/bon_Commandes")}
          >
            Bon commande
          </Menu.Item>
          <Menu.Item
            icon={<ShoppingCartOutlined />}
            disabled={!Can("15_1")}
            key="10"
            onClick={() => navigate("/v/bon_livraisons")}
          >
            Bon livraison
          </Menu.Item>
          <Menu.Item
            icon={<OneToOneOutlined />}
            disabled={!Can("14_8")}
            key="8"
            onClick={() => navigate("/accompte")}
          >
            Acompte
          </Menu.Item>
          <Menu.Item
            icon={<AuditOutlined />}
            disabled={!Can("16_1")}
            key="11"
            onClick={() => navigate("/facture_vente")}
          >
            Facture
          </Menu.Item>
        </SubMenu> */}
        
        <SubMenu key="Stock" icon={<HddOutlined />} title="Stock">
          <Menu.Item
            key="20"
            icon={<GoldOutlined />}
            disabled={!Can("20_1")}
            onClick={() => navigate("/stock")}
          >
            Stock M.P
          </Menu.Item>
          <Menu.Item
            icon={<PartitionOutlined />}
            key="34"
            onClick={() => navigate("/stock_coupe")}
          >
            Stock coupe
          </Menu.Item>
          <Menu.Item
            icon={<GroupOutlined />}
            key="35"
            onClick={() => navigate("/stock_broderie")}
          >
            Stock broderie
          </Menu.Item>
          
          <Menu.Item
            icon={<OneToOneOutlined />}
            key="36"
            onClick={() => navigate("/stock_assemblage")}
          >
            Stock Assemblage
          </Menu.Item>

          <Menu.Item
            icon={<SkinOutlined />}
            key="37"
            onClick={() => navigate("/stock_produits")}
          >
            Stock produits
          </Menu.Item>
          

          <Menu.Item
            key="21"
            icon={<FileSyncOutlined />}
            disabled={!Can("21_1")}
            onClick={() => navigate("/bon_transfert")}
          >
            Bon de transfert
          </Menu.Item>
          <Menu.Item
            key="22"
            icon={<PullRequestOutlined />}
            disabled={!Can("22_1")}
            onClick={() => navigate("/bon_retour")}
          >
            Bon de retour
          </Menu.Item>
          <Menu.Item
            key="23"
            icon={<SlidersOutlined />}
            disabled={!Can("23_1")}
            onClick={() => navigate("/list/inventaires")}
          >
            Inventaire
          </Menu.Item>
          <Menu.Item
            key="24"
            icon={<SlidersOutlined />}
            disabled={!Can("23_1")}
            onClick={() => navigate("/mouvement_stock")}
          >
            Mouvement Stock
          </Menu.Item>
        </SubMenu>
        <Menu.Item icon={<ShoppingCartOutlined />} key="13" onClick={() => navigate("/s/bon_livraisons")}>
          Bon livraison 
        </Menu.Item>

        {/* gestion finanicer */}
        <SubMenu key="Gestion Financier" icon={<HddOutlined />} title="Gestion Financier">
        <Menu.Item
            key="7"
            icon={<FileSyncOutlined />}
            disabled={!Can("21_1")}
            onClick={() => navigate("/finance_client")}
          >
            Finance Client
          </Menu.Item>
          <Menu.Item
            key="8"
            icon={<FileSyncOutlined />}
            disabled={!Can("21_1")}
            onClick={() => navigate("/finance_fournisseur")}
          >
            Finance Founisseur
          </Menu.Item>
        </SubMenu>


        <Menu.Item
          key="15"
          icon={<AppstoreAddOutlined />}
          disabled={!Can("24_1")}
          onClick={() => navigate("/produit")}
        >
          M.P / P.F
        </Menu.Item>
        
        <Menu.Item
          key="18"
          icon={<TeamOutlined />}
          disabled={!Can("26_1")}
          onClick={() => navigate("/employe")}
        >
          Employées
        </Menu.Item>
        <Menu.Item
          key="19"
          icon={<TeamOutlined />}
          disabled={!Can("27_1")}
          onClick={() => navigate("/users")}
        >
          Utilisateurs
        </Menu.Item>
      </Menu>
    </>
  );
}
export default SideNavigation;
